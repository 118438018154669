(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/racing/build-unique-rank-row/assets/javascripts/build-unique-rank-rows.js') >= 0) return;  svs.modules.push('/components/racing/build-unique-rank-row/assets/javascripts/build-unique-rank-rows.js');

'use strict';

const buildUniqueRankRows = (positions, numPositions) => {
  const allRows = [];
  const buildRow = (rankIndex, currentRow) => {
    for (let i = 0; i < positions[rankIndex].length; i++) {
      const currentValue = positions[rankIndex][i];

      if (currentRow.includes(currentValue)) {
        continue;
      }

      const concatedRow = currentRow.concat(currentValue);
      if (rankIndex === numPositions - 1) {
        allRows.push(concatedRow);
      } else {
        buildRow(rankIndex + 1, concatedRow);
      }
    }
  };
  buildRow(0, []);
  return allRows;
};
if (svs.isServer) {
  module.exports = buildUniqueRankRows;
} else {
  svs.racing.build_unique_rank_row.buildUniqueRankRows = buildUniqueRankRows;
}

 })(window);